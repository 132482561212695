import instaLogo from './img/instagram_black.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
         Hello! Thanks for visiting my website. It's currently under construction but you can find me and my artworks here:
        </p>
        <a
          className="Instagram-link"
          href="https://www.instagram.com/lidia_gorbunova/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instaLogo} className="Instagram-icon" alt="instagram-link" />
          lidia_gorbunova
        </a>
      </header>
    </div>
  );
}

export default App;
